import { ROUTE_PATHS, SUPPORT_EMAIL } from '@tapestry/shared/constants';
import { Button } from '@tapestry/weave';
import Link from 'next/link';
import React, { FC } from 'react';

export const CodeScannerProductNotFound: FC<{
  flushFailureState: () => void;
  handleGoBack: () => void;
  isError: boolean;
}> = ({ flushFailureState, handleGoBack, isError }) => {
  return (
    <div className="text-gray-text h-screen flex-col place-content-center space-y-12 px-4 text-center">
      <div className="space-y-2">
        <h1 className="font bold text-lg">
          {isError
            ? 'An error occured searching for your product'
            : 'Your product could not be found'}
        </h1>

        {isError ? (
          <p>
            <a
              href={`mailto:${SUPPORT_EMAIL}`}
              className="text-primary underline"
            >
              Support
            </a>{' '}
            has been contacted.
            <br /> In the meantime, You could try to reload the page and try
            again.
          </p>
        ) : (
          <p>
            Scan a different product or go to the search page to look it up by
            name, etc..
          </p>
        )}
      </div>

      <div className="space-y-6">
        <div className="flex flex-col items-center space-y-2">
          <Button fullWidth status="primary" onClick={flushFailureState}>
            Restart scanner
          </Button>

          <Link href={ROUTE_PATHS.search} className="w-full">
            <Button fullWidth status="primary">
              Go to Search page
            </Button>
          </Link>

          <Link href={ROUTE_PATHS.heartbeat} className="w-full">
            <Button fullWidth status="secondary">
              Go to Real-time Analytics
            </Button>
          </Link>
          <Button fullWidth status="secondary" onClick={handleGoBack}>
            Return to previous page
          </Button>
        </div>
      </div>

      {!isError && (
        <p>
          If you think this is a error, feel free to contact{' '}
          <a
            href={`mailto:${SUPPORT_EMAIL}`}
            className="text-primary underline"
          >
            support
          </a>
        </p>
      )}
    </div>
  );
};
