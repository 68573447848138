import { useRouter } from 'next/router';
import { FC } from 'react';

export const CodeScannerPermissionRefusedState: FC = () => {
  const router = useRouter();

  return (
    <div className="text-gray-text fixed top-0 mt-24 space-y-2 px-4">
      <h1 className="font bold text-lg">
        You have blocked access to your camera
      </h1>
      <p>
        If this was a mistake, please reset your permissions and reload the
        page.
      </p>
      <p>
        Otherwise you can{' '}
        <button
          onClick={router.back}
          onKeyDown={({ key }) => {
            if (key === 'Enter') {
              router.back();
            }
          }}
          className="text-primary underline"
        >
          go back where you came from
        </button>
      </p>
    </div>
  );
};
