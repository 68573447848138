import { Transition } from '@headlessui/react';
import {
  ChevronLeftIcon,
  CloseIcon,
  KeyboardIcon,
  PaperPlanIcon,
} from '@tapestry/shared/icons';
import { Circle, FormTextInput } from '@tapestry/weave';
import { FC, useState } from 'react';
import { WidgetBar, WidgetBarButton } from '../../widget-bar';
import { stopScanTimerAndRetrieveTime } from '../utils/scanner-timer-utils';
import { trackEvent } from '@tapestry/shared/utils';
import { useRouter } from 'next/router';

interface FloatingActionsProps {
  onSubmitInput: (decodedValue: string) => void;
  runScanningLoop: (active: boolean) => void;
}

export const FloatingActions: FC<FloatingActionsProps> = ({
  onSubmitInput,
  runScanningLoop,
}) => {
  const router = useRouter();
  const [inputValue, setInputValue] = useState('');
  const [isManualEntryMode, setIsManualEntryMode] = useState(false);

  const handleOnInputValueSubmit = () => {
    onSubmitInput(inputValue);
  };

  const handleEnterManualEntry = () => {
    trackEvent({
      event: 'CodeScanner',
      category: 'code_scanner',
      action: 'scanner_manual_entry_started',
      label: String(stopScanTimerAndRetrieveTime()),
      value: stopScanTimerAndRetrieveTime(),
    });
    runScanningLoop(false);
    setIsManualEntryMode(true);
  };

  const handleLeaveManualEntry = () => {
    trackEvent({
      event: 'CodeScanner',
      category: 'code_scanner',
      action: 'scanner_manual_entry_finished',
    });

    setIsManualEntryMode(false);
    runScanningLoop(true);
  };

  const handleCancel = () => {
    trackEvent({
      event: 'CodeScanner',
      category: 'code_scanner',
      action: 'scanner_user_cancelled',
      label: String(stopScanTimerAndRetrieveTime()),
      value: stopScanTimerAndRetrieveTime(),
    });
    runScanningLoop(false);
    router.back();
  };

  return (
    <>
      <Transition
        as="div"
        show={!isManualEntryMode}
        appear
        enter="transition transform duration-200 ease-out"
        enterFrom="translate-y-80"
        enterTo="translate-y-0"
        leave="transition transform duration-200 ease-in-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        unmount={false}
      >
        <WidgetBar>
          <WidgetBarButton
            title="Enter code manually"
            action="scanner-code-manual-entry"
            onPress={handleEnterManualEntry}
            icon={<KeyboardIcon />}
          />

          <WidgetBarButton
            title="Cancel scan"
            action="scanner-cancel-scan"
            onPress={handleCancel}
            icon={<CloseIcon />}
          />
        </WidgetBar>
      </Transition>

      <Transition
        as="div"
        show={isManualEntryMode}
        enter="transition transform duration-500 ease-out-circ"
        enterFrom="translate-y-32 opacity-0"
        enterTo="translate-y-0 opacity-100"
        leave="transition transform duration-300"
        leaveFrom="translate-y-0 opacity-100"
        leaveTo="translate-y-32 opacity-0"
      >
        <div className="bg-gray-input absolute bottom-0 flex w-full items-center justify-between space-x-4 rounded-t py-2 pl-2 pr-4">
          <button
            className="-mr-2 flex-shrink-0"
            onClick={handleLeaveManualEntry}
            onKeyDown={({ key }) => {
              if (key === 'Enter') {
                handleLeaveManualEntry();
              }
            }}
            title="Close keyboard entry"
            type="button"
          >
            <Circle className="border-none">
              <p className="sr-only">Close keyboard entry</p>
              <ChevronLeftIcon />
            </Circle>
          </button>

          <div className="flex-1">
            <FormTextInput
              label="code"
              name="code"
              labelSROnly
              value={inputValue}
              // @ts-ignore TODO[low]: fix the interfac type of the input to extend HTMLInputElement properly
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>

          <button
            disabled={!inputValue}
            className="group flex-shrink-0 rounded-full"
            onClick={() => handleOnInputValueSubmit()}
            title="submit code"
            type="button"
          >
            <Circle className="bg-primary group-hover:bg-primary-dark group-focus:bg-primary-dark text-white">
              <p className="sr-only">Submit code</p>
              <PaperPlanIcon fillColor="currentColor" />
            </Circle>
          </button>
        </div>
      </Transition>
    </>
  );
};
