import React, { useRef, useEffect, useState } from 'react';
import { scanImageData, ZBarSymbol } from '@undecaf/zbar-wasm';
import { FloatingActions } from './FloatingActions';
import { getOffCtx2d } from '../utils/canva-functions';
import { trackEvent } from '@tapestry/shared/utils';
import { stopScanTimerAndRetrieveTime } from '../utils/scanner-timer-utils';

let frameRequestId: number | null = null;

type DecodedSymbols = Array<Omit<ZBarSymbol, 'decode'> & { rawValue: string }>;

interface CameraViewProps {
  onScanSuccess: (decodedText: string) => void;
}

const CameraView: React.FC<CameraViewProps> = ({ onScanSuccess }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // TODO[low]: Will be used in next iteration to add visual feedback that the scanner is running
  const [_, setIsScanning] = useState(false);

  const handleScanSuccess = (decodedValue: string) => {
    runScanningLoop(false);

    trackEvent({
      event: 'CodeScanner',
      category: 'code_scanner',
      action: 'scanner_success',
      label: String(stopScanTimerAndRetrieveTime()),
      value: stopScanTimerAndRetrieveTime(),
    });

    onScanSuccess(decodedValue);
  };

  const scanWithWASM = async (source: HTMLVideoElement) => {
    const canvas = canvasRef.current;

    if (!canvas) return Promise.resolve();

    const ctx = canvas.getContext('2d');

    canvas.width = source.videoWidth || source.width;
    canvas.height = source.videoHeight || source.height;

    if (canvas.height && canvas.width) {
      const offCtx = getOffCtx2d(canvas.width, canvas.height) || ctx;
      if (!offCtx) return Promise.resolve();

      offCtx.drawImage(source, 0, 0);

      const imageData = offCtx.getImageData(0, 0, canvas.width, canvas.height);

      return scanImageData(imageData)
        .then((symbols) => {
          // Decode
          const decodedSymbols: DecodedSymbols = symbols.map((s) => ({
            ...s,
            rawValue: s.decode('utf-8'),
          }));

          const firstDecodedValue = decodedSymbols[0]?.rawValue;

          if (firstDecodedValue) {
            handleScanSuccess(firstDecodedValue);

            // * stop tracks
            if (videoRef.current && videoRef.current.srcObject) {
              const stream = videoRef.current.srcObject as MediaStream;
              stream.getTracks().forEach((track) => track.stop());
            }

            return Promise.resolve();
          }
        })
        .catch(() => {
          trackEvent({
            event: 'CodeScanner',
            category: 'code_scanner',
            action: 'scanner_error',
            label: 'wasm',
          });
        });
    } else {
      return Promise.resolve();
    }
  };

  // ! this is the main loop that runs every frame
  const runScanningLoop = async (active: boolean) => {
    if (active && videoRef.current) {
      await scanWithWASM(videoRef.current);
      // * could add other scanners here tentatively

      frameRequestId = requestAnimationFrame(() => runScanningLoop(true));
    } else {
      frameRequestId && cancelAnimationFrame(frameRequestId);
      frameRequestId = null;
      setIsScanning(false);
    }
  };

  useEffect(function StartStream() {
    const currentVideoRef = videoRef.current;

    const startCamera = async () => {
      try {
        // TODO[high] - HERE is the booting - still gotta work out the height zooming
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: { ideal: 'environment' },
            // 4k width helps targeting the tele camera instead of the panoramic camera (on samsungs for example)
            width: { ideal: 4096 },
            // TODO[high]: play with the below to do heigh scaling to viewport
            // ? although this makes it default to the front camera ?
            // height: { ideal: window.innerHeight },
            // aspectRatio: { ideal: 1 / 5 },
          },
        });

        if (currentVideoRef) {
          currentVideoRef.srcObject = stream;
          runScanningLoop(true);
          setIsScanning(true);

          trackEvent({
            event: 'CodeScanner',
            category: 'code_scanner',
            action: 'scanner_ready',
            label: 'wasm-navigator',
          });
        }
      } catch (error) {
        console.error('Error accessing camera:', error);

        trackEvent({
          event: 'CodeScanner',
          category: 'code_scanner',
          action: 'scanner_fail_to_start',
          label: 'navigator',
        });
      }
    };

    startCamera();

    return () => {
      if (currentVideoRef && currentVideoRef.srcObject) {
        const stream = currentVideoRef.srcObject as MediaStream;
        stream.getTracks().forEach((track) => track.stop());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-full overflow-x-hidden bg-black">
      <video ref={videoRef} autoPlay playsInline className="h-full" />
      {/* this probably might need to be changed to not be hidden */}
      <canvas ref={canvasRef} className="hidden" />

      <FloatingActions
        onSubmitInput={handleScanSuccess}
        runScanningLoop={runScanningLoop}
      />
    </div>
  );
};

export default CameraView;
