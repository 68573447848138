let scanStartTime: number | null = null;

export const startScanTimer = () => {
  scanStartTime = Date.now();
};

/**
 * returns the time in seconds
 */
export const stopScanTimerAndRetrieveTime = () => {
  if (scanStartTime) {
    const scanTimeInMS = Date.now() - scanStartTime;
    const scanTimeInSecondsRounded = Math.round(scanTimeInMS / 1000);

    return scanTimeInSecondsRounded;
  }

  scanStartTime = null;
};
