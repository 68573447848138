// This is the tapestry abstraction on date time
// it allows to create the api we want, a more declarative one
// also if we need to change the library in the future, it's easy

import { IsoString } from '@tapestry/types';
import dayjs, { OpUnitType, QUnitType } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import isoWeek from 'dayjs/plugin/isoWeek';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { APP_DEFAULT_TIMEZONE } from '@tapestry/shared/constants';
import calendar from 'dayjs/plugin/calendar';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(calendar);
dayjs.extend(advancedFormat);

const setDefaultTimezone = (timezone = APP_DEFAULT_TIMEZONE) => {
  dayjs.tz.setDefault(timezone);
};

const parse = (
  date: string | Date | null,
  format?: dayjs.OptionType | undefined,
  strict?: boolean
) => {
  return dayjs(date, format, strict);
};

const parseInUTC = (
  date: string | Date | undefined,
  format?: dayjs.OptionType | undefined
) => {
  return dayjs(date, format).utc();
};

/**
 * use to parse with timezone aware
 */
const parseInTimezone = (
  date: string | Date | undefined,
  timezone: string = APP_DEFAULT_TIMEZONE,
  parsingFormat: string
) => {
  return dayjs.tz(date, parsingFormat, timezone);
};

const convertToTimezone = (
  date: string | Date | undefined,
  timezone: string = APP_DEFAULT_TIMEZONE,
  parsingFormat?: string
) => {
  return dayjs(date, parsingFormat).tz(timezone);
};

const getCurrentYear = (
  date: string,
  format?: string,
  parsingFormat?: string
): string => {
  return dayjs(date, parsingFormat).format(format || 'YYYY');
};

const getCurrentMonth = (
  date: string,
  format?: string,
  parsingFormat?: string
): string => {
  return dayjs(date, parsingFormat).format(format || 'MMM');
};

const getDay = (date: string, parsingFormat?: string): number => {
  return dayjs(date, parsingFormat).date();
};

const getDayOfWeek = (date: string, parsingFormat?: string): number => {
  return dayjs(date, parsingFormat).day();
};

const getHour = (
  date: string,
  format?: string,
  parsingFormat?: string
): string => {
  return dayjs(date, parsingFormat).format(format || 'hh:mm a');
};

const format = (
  date: string | IsoString | Date,
  format = 'dddd, D MMMM YYYY',
  parsingFormat?: string
) => {
  return dayjs(date, parsingFormat).format(format);
};

const fromNow = (datetime: string | IsoString | undefined | null) => {
  if (!datetime || typeof datetime !== 'string') return null;

  return dayjs(datetime).fromNow();
};

const toNow = (datetime: string | IsoString | undefined | null) => {
  if (!datetime || typeof datetime !== 'string') return null;

  return dayjs(datetime).toNow();
};

const isBeforeNow = (
  datetime: string | IsoString | undefined | null,
  granularity?: QUnitType
) => {
  if (!datetime) return false;
  const now = dayjs();

  return dayjs(datetime).isBefore(now, granularity);
};

const isBefore = (
  datetime: string | IsoString,
  reference: string | IsoString
) => {
  return dayjs(datetime).isBefore(dayjs(reference));
};

const isAfterNow = (datetime: string | IsoString | undefined | null) => {
  if (!datetime) return false;
  const now = dayjs();

  return dayjs(datetime).isAfter(now);
};

const isAfter = (
  datetime: string | IsoString,
  reference: string | IsoString
) => {
  return dayjs(datetime).isAfter(dayjs(reference));
};

type Inclusivity = '[]' | '()' | '[)' | '(]' | undefined;

const checkIsBetween = (
  toCheck,
  start,
  end,
  limit: OpUnitType = 'day',
  // includes start and end date
  inclusivity: Inclusivity = '[]'
) => {
  return dayjs(toCheck).isBetween(start, end, limit, inclusivity);
};

const now = (utc = false) => {
  return utc ? dayjs().utc() : dayjs();
};

/**
 * provide the timezone of the dates provided for accurate reading
 */
const isSameDate = (
  startDate: string | IsoString | dayjs.Dayjs | undefined | null,
  endDate: string | IsoString | dayjs.Dayjs | undefined | null,
  datesTimezone?: string,
  parsingFormat?: string
) => {
  if (!startDate || !endDate) {
    return false;
  }

  const _startDate = datesTimezone
    ? dayjs(startDate, parsingFormat).tz(datesTimezone)
    : dayjs(startDate, parsingFormat);
  const _endDate = datesTimezone
    ? dayjs(endDate, parsingFormat).tz(datesTimezone)
    : dayjs(endDate, parsingFormat);

  return _startDate.startOf('date').isSame(_endDate.startOf('date'));
};

/**
 *
 */
const diff = (
  startDate: string,
  endDate: string,
  diffUnit: OpUnitType,
  parsingFormat?: string
) => {
  if (!diffUnit) {
    throw new Error('datetime.diff(): diffUnit is required');
  }

  return dayjs(endDate, parsingFormat).diff(
    dayjs(startDate, parsingFormat),
    diffUnit
  );
};

const diffInDays = (
  startDate: string,
  endDate: string,
  datesTimezone: string = APP_DEFAULT_TIMEZONE
) => {
  if (isSameDate(startDate, endDate, datesTimezone)) {
    return 0;
  }

  const [_startDate, _endDate] = [startDate, endDate].sort((a, b) =>
    isAfter(a, b) ? 1 : -1
  );

  const startOfDate = dayjs(_startDate)
    .tz(datesTimezone)
    .startOf('date')
    .format();
  const endOfDate = dayjs(_endDate).tz(datesTimezone).endOf('date').format();

  return diff(startOfDate, endOfDate, 'days') + 1;
};

/**
 *
 */
const trailingMonth = (timezone?: string) => {
  const tz = timezone || APP_DEFAULT_TIMEZONE;
  const endDate = dayjs().tz(tz).endOf('day').format();
  const startDate = dayjs(endDate).tz(tz).subtract(30, 'day').format();

  return {
    startDate,
    endDate,
  };
};

/**
 *
 */
const getCurrentIsoWeek = (timezone?: string) => {
  const _now = now().tz(timezone || APP_DEFAULT_TIMEZONE);
  const startDate = _now.startOf('isoWeek' as OpUnitType).format();
  const endDate = _now.endOf('isoWeek' as OpUnitType).format();

  return {
    startDate,
    endDate,
  };
};

const getUserTimezone = () => {
  return dayjs.tz.guess();
};

const calendarWithFormats = (
  datetime: string | undefined | null,
  formats: object
) => {
  if (!datetime || typeof datetime !== 'string') return null;

  return dayjs(datetime).calendar(null, formats);
};

const isLastDayOfMonth = (date: string | Date): boolean => {
  const day = dayjs(date);
  const lastDayOfMonth = day.endOf('month');

  return day.isSame(lastDayOfMonth, 'day');
};

export const dateTime = {
  getCurrentMonth,
  getDay,
  getHour,
  getDayOfWeek,
  format,
  fromNow,
  toNow,
  isBefore,
  isBeforeNow,
  isAfter,
  isAfterNow,
  now,
  checkIsBetween,
  parse,
  parseInTimezone,
  convertToTimezone,
  isSameDate,
  getCurrentYear,
  diff,
  diffInDays,
  trailingMonth,
  getCurrentIsoWeek,
  setDefaultTimezone,
  getUserTimezone,
  parseInUTC,
  calendarWithFormats,
  isLastDayOfMonth,
};

export const isISOString = (string: string | IsoString) => {
  if (!string) return false;

  return string.endsWith('Z');
};
