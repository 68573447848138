import React, { useEffect, useState } from 'react';
import { CodeScannerPermissionRefusedState } from './ScannerPermissionRefusedState';
import { trackEvent } from '@tapestry/shared/utils';

interface PermissionRequestProps {
  setHasPermission: (hasPermission: boolean) => void;
}

const PermissionRequest: React.FC<PermissionRequestProps> = ({
  setHasPermission,
}) => {
  const [hasRefused, setHasRefused] = useState(false);

  useEffect(
    function checkPermission() {
      (async () => {
        try {
          const result = await navigator.permissions.query({
            name: 'camera' as PermissionName,
          });

          if (result.state === 'granted') {
            setHasPermission(true);
          } else if (result.state === 'prompt') {
            // * prompt permission
            const stream = await navigator.mediaDevices.getUserMedia({
              video: true,
            });
            // Cleanup
            stream.getTracks().forEach((track) => track.stop());

            setHasPermission(true);
            setHasRefused(false);
          } else {
            setHasRefused(true);
            setHasPermission(false);
          }
        } catch (error) {
          // If permission refused...
          console.error('Error checking camera permission:', error);

          setHasPermission(false);
          setHasRefused(true);

          trackEvent({
            event: 'CodeScanner',
            category: 'code_scanner',
            action: 'scanner_permission_denied',
          });
        }
      })();
    },
    [setHasPermission]
  );

  if (hasRefused) {
    return <CodeScannerPermissionRefusedState />;
  }

  return (
    <div className="text-gray-text h-screen flex-col place-content-center px-4 text-center">
      <h1 className="text-lg">Checking your camera permissions...</h1>
    </div>
  );
};

export default PermissionRequest;
