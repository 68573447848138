let offCanvas: OffscreenCanvas | null = null;

export function isOffscreenCanvasWorking() {
  try {
    return Boolean(new OffscreenCanvas(1, 1).getContext('2d'));
  } catch {
    return false;
  }
}

const usingOffscreenCanvas = isOffscreenCanvasWorking();

export function getOffCtx2d(width, height) {
  if (usingOffscreenCanvas) {
    if (
      !offCanvas ||
      offCanvas.width !== width ||
      offCanvas.height !== height
    ) {
      // Only resizing the canvas caused Chromium to become progressively slower
      offCanvas = new OffscreenCanvas(width, height);
    }

    return offCanvas.getContext('2d');
  }
}
